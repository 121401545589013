* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
}

body{
  background-color: rgb(241, 241, 241);
}

button:hover{
  cursor: pointer;
}
