.AltLandingPage-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AltLandingPage-container .bg-image {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  filter: brightness(80%);
}

.AltLandingPage-container .txt-container h1 {
  font-size: 50px;
  color: white;
}

.AltLandingPage-container .txt-container span {
  color: rgb(124, 0, 0);
}

.AltLandingPage-container .btn-container {
  margin-bottom: 50px;
}

.AltLandingPage-container .btn-container button {
  margin: 20px 40px;
  height: 40px;
  width: 150px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(124, 0, 0);
  background-color: white;

  /* transition */

  transition: all 0.5s;
}

.AltLandingPage-container .btn-container .support-btn {
  background-color: rgb(124, 0, 0);
  color: white;
}

.AltLandingPage-container .btn-container button:hover {
  background-color: rgb(124, 0, 0);
  color: white;
}

.AltLandingPage-container .btn-container .support-btn:hover {
  background-color: white;
  color: rgb(124, 0, 0);
}
