.our-robot-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 100px;
}

.our-robot-container h1 {
  margin-top: 30px;
  margin-bottom: 70px;

  font-size: 40px;
}

.our-robot-container .card-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 70px;
  width: 100vw;
}

.our-robot-container .card-container .card {
  display: flex;
  width: 80vw;
  margin-bottom: 50px;
}

.our-robot-container .card-container .card img {
  margin-right: 20px;
  width: 300px;
}

.our-robot-container .card-container .card h2 {
  margin-top: -8px;
  margin-bottom: 5px;
}
