.sponsor-container {
  margin-top: 100px;

  /* display */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsor-container h1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.sponsor-container .sponsor-body-container {
  display: flex;
  justify-content: space-between;
}

.sponsor-container .sponsor-body-container img {
  width: 40vw;
}

.sponsor-container .sponsor-body-container p {
  width: 40vw;
  margin: 0px 3vw;
}

/* h1 {
  margin-left: 40%;
  margin-top: 40%;
} */
