.about-contaienr{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin-top: 100px;
}

.about-contaienr h1{
    padding-top: 30px;
    padding-bottom: 60px;
    font-size: 36px;
}

.about-contaienr .text-container{
    display: flex;
    justify-content: space-between;
    width: 100vw;
    padding: 0px 10vw;
    margin-bottom: 40px;
}

.about-contaienr .text-container h2{
    margin-bottom: 15px;
}

.about-contaienr .text-container p{
    width: 30vw;
}