.compitition-container {
  margin: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compitition-container h1 {
  font-size: 46px;
  margin-top: 30px;
  margin-bottom: 70px;
}

.compitition-container .comp-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 50px;
  width: 100vw;
}

.compitition-container .comp-card-container .card {
  margin: 0vw 10vw;
  width: 30vw;
  display: flex;
  flex-direction: column;
}

.compitition-container .comp-card-container .card h2 {
  font-size: 25px;
}

.compitition-container .comp-card-container .card img {
  width: 30vw;
  margin: 5px 0px;
}

.compitition-container .comp-card-container .card p {
  width: 30vw;
}
