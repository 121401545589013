.featured-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featured-container h1 {
  margin: 10vh 0px;
  font-size: 40px;
}

.featured-container .btn-container {
  display: flex;
  justify-content: space-between;
  width: 45vw;
}

.featured-container .btn-container .btn-background {
  height: 100px;
  width: 100px;
  border: none;
  border-radius: 50px;
  background-color: rgb(82, 0, 0);

  /* display */
  display: flex;
  align-items: center;
  justify-content: center;
}

.featured-container .btn-container .btn-background img{
  width: 50px;
}


.featured-container .divider{
  height: 0px;
  width: 80vw;
  border: 1px solid;
  border-color: rgb(184, 184, 184);
  margin: 20vh 0px;
}