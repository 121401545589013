.robots-contianer {
  height: 600px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.robots-contianer h1 {
  font-size: 36px;
  padding-bottom: 50px;
}

.robots-contianer .img-contianer {
  width: 650px;
  display: flex;
  justify-content: space-between;
}

.robots-contianer .img-contianer .Robiwan-img {
  width: 300px;
}

.robots-contianer .img-contianer .grito-img {
  width: 300px;
}
